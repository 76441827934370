<template>
  <div>
    <div class="card bg-light-primary mt-3">
      <div class="card-header bg-light-primary">
        {{ inspection.section_start.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in inspection.section_start.questions"
          v-show="
            (getUuid !== null &&
              question.questName !== 'Где проводил' &&
              question.questName !== 'Когда проводил' &&
              question.questName !== 'Комментарий') ||
              getUuid === null
          "
          :key="question.order"
          :parent="inspection.section_start"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div>

    <div
      class="card bg-light-warning mt-3"
      v-for="section in inspection.sections"
      :key="section.order"
    >
      <div class="card-header bg-light-warning">
        {{ section.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in section.questions"
          :key="question.id"
          :parent="section"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div>

    <div
      class="card bg-light-danger mt-3"
      v-if="process.processType && inspection.hasOwnProperty('section_finish')"
    >
      <div class="card-header bg-light-danger">
        {{ inspection.section_finish.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in inspection.section_finish.questions"
          :key="question.order"
          :parent="inspection.section_start"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "./Question";

export default {
  name: "Inspection",

  components: {
    Question
  },

  props: {
    process: Object,
    inspection: Object
  },

  data: () => ({}),

  computed: {
    ...mapGetters(["getUuid"])
  },

  created() {},

  mounted() {},

  methods: {}
};
</script>
