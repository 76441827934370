<template>
  <div class="container-fluid mb-5">
    <div class="row">
      <label class="col-12"
        ><strong>{{ question.partition }}</strong
        ><br />{{ question.questName }}
        <span
          :class="{
            'text-danger':
              typeof question.answer === 'undefined' ||
              question.answer === null ||
              (Array.isArray(question.answer) && question.answer.length < 1)
          }"
          v-if="question.requared"
          >*</span
        ></label
      >
    </div>
    <div
      class="row"
      v-if="
        question.fileData_id !== null &&
          question.fileData_id !== '' &&
          question.dataTypeId_id !== 11
      "
    >
      <div v-html="showFile(question)" class="w-100 mt-3 mb-3"></div>
    </div>
    <!-- type = 1 / type = 4 / type = 10 -->
    <div
      class="row"
      v-if="
        question.dataTypeId_id === 1 ||
          question.dataTypeId_id === 4 ||
          question.dataTypeId_id === 10
      "
    >
      <div class="col-12">
        <b-form-radio-group v-model="question.answer" buttons>
          <b-form-radio
            v-for="answer in question.answers"
            :key="answer.id"
            :value="answer.id"
            :button-variant="
              answer.answerValue === 'Да'
                ? 'outline-success'
                : answer.answerValue === 'Нет'
                ? 'outline-danger'
                : answer.answerValue === 'Не знаю'
                ? 'outline-secondary'
                : answer.answerValue === 'Низкая'
                ? 'outline-info'
                : answer.answerValue === 'Средняя'
                ? 'outline-warning'
                : answer.answerValue === 'Высокая'
                ? 'outline-danger'
                : 'outline-primary'
            "
          >
            <template v-if="answer.fileData_id !== null"
              ><img :src="answer.fileData_id" style="max-width:120px;" /><br />
            </template>
            {{ answer.answerValue }}
          </b-form-radio>
        </b-form-radio-group>

        <div
          v-if="
            question.answer !== null &&
              question.answers[
                question.answers.findIndex(i => i.id === question.answer)
              ].hasOwnProperty('questions')
          "
          class="card pt-3 mt-3"
          :class="bgs[current_bg]"
        >
          <div class="card-body">
            <Question
              v-for="subq in question.answers[
                question.answers.findIndex(i => i.id === question.answer)
              ].questions"
              :key="subq.id"
              :parent="
                question.answers[
                  question.answers.findIndex(i => i.id === question.answer)
                ]
              "
              :question="subq"
              :bg="current_bg"
            ></Question>
          </div>
        </div>
      </div>
    </div>

    <!-- type = 2 -->
    <div class="row" v-if="question.dataTypeId_id === 2">
      <div class="col-12">
        <b-form-checkbox-group buttons stacked v-model="question.answer">
          <b-form-checkbox
            v-for="answer in question.answers"
            :key="answer.id"
            :value="answer.id"
            @change="createSubs"
            button
            stacked
            button-variant="outline-info"
          >
            <template v-if="answer.fileData_id !== null"
              ><img :src="answer.fileData_id" style="max-width:120px;" /><br />
            </template>
            {{ answer.answerValue }}
          </b-form-checkbox>
        </b-form-checkbox-group>
        <!-- subquestions all in one -->
        <div
          v-if="question.answer !== null && subquestions.length"
          class="card pt-3 mt-3"
          :class="bgs[current_bg]"
        >
          <div class="card-body">
            <Question
              v-for="subq in subquestions"
              :key="subq.id"
              :parent="
                question.answers.find(i => question.answer.includes(i.id))
              "
              :question="subq"
              :bg="current_bg"
            ></Question>
          </div>
        </div>
      </div>
    </div>

    <!-- type = 3 -->
    <div class="row" v-if="question.dataTypeId_id === 3">
      <div class="col-12">
        <div class="form-group row">
          <input
            type="text"
            class="form-control"
            v-model.trim="question.answer"
          />
        </div>
      </div>
    </div>

    <!-- type = 13 -->
    <div class="row" v-if="question.dataTypeId_id === 13">
      <div class="col-12">
        <div class="form-group row">
          <input
            type="number"
            class="form-control"
            v-model.number="question.answer"
          />
        </div>
      </div>
    </div>

    <!-- type = 5  -->
    <div class="row" v-if="question.dataTypeId_id === 5">
      <div class="col-12">
        <div
          class="form-group row"
          v-if="question.answer === '' || question.answer === null"
        >
          <input
            type="file"
            class="form-control"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div v-else>
          <img class="img-fluid" :src="question.answer" />
          <button @click="removeImage(question)">Удалить</button>
        </div>
      </div>
    </div>

    <!-- type = 6 -->
    <div class="row" v-if="question.dataTypeId_id === 6">
      <div class="col-12">
        <div class="form-group row">
          <input
            type="text"
            class="form-control"
            ref="users"
            autocomplete
            v-model.trim="question.answer"
          />
        </div>
      </div>
    </div>

    <!-- type = 7 -->
    <div class="row" v-if="question.dataTypeId_id === 7">
      <div class="col-12">
        <div class="form-group row">
          <b-form-datepicker
            v-model="question.answer"
            locale="ru"
            :min="today"
            :start-weekday="1"
            label-help="Используйте стрелки на клавиатуре для выбора дат"
            :readonly="question.partition === 'Общая информация'"
          ></b-form-datepicker>
        </div>
      </div>
    </div>

    <!-- type = 8 -->
    <div class="row" v-if="question.dataTypeId_id === 8">
      <div class="col-12">
        <div class="form-group row">
          <input
            type="text"
            class="form-control"
            v-model.trim="question.answer"
          />
        </div>
      </div>
    </div>

    <!-- type = 9 -->
    <div class="row" v-if="question.dataTypeId_id === 9">
      <div class="col-12">
        <div v-html="createFile(question)"></div>
      </div>
      <div class="col-12">
        <div class="form-group row">
          <input type="date" class="form-control" v-model="question.answer" />
        </div>
      </div>
    </div>

    <!-- type = 11 -->
    <div class="row" v-if="question.dataTypeId_id === 11">
      <div class="col-12">
        <div class="form-group row">
          <canvas
            ref="canvas"
            class="border border-primary rounded bg-white test-class"
            height="300"
            @mousedown="beginDrawing"
            @mousemove="keepDrawing"
            @mouseup="stopDrawing"
            @touchstart="handleStart"
            @touchend="handleEnd"
            @touchcancel="handleCancel"
            @touchmove="handleMove"
          ></canvas>
          <a href="#" @click.prevent="clearCanvas" class="clearfix">Очистить</a>
        </div>
      </div>
    </div>

    <!-- type = 12 -->
    <div class="row" v-if="question.dataTypeId_id === 12">
      <div class="col-12">
        <div class="form-group row">
          <input
            type="range"
            class="form-control"
            min="0"
            max="100"
            v-model="question.answer"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between border-top pt-10">
      <div>
        <button
          v-on:click="submitQuestion"
          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
        >
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<style>
input.form-control[type="range"] {
  padding-left: 0;
  padding-right: 0;
}
.btn-group {
  display: block;
}
</style>

<script>
//import Answer from "./Answer";
import ApiService from "@/core/services/api.service";
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";
import Swal from "sweetalert2";

import { SET_QUESTION_INDEX } from "@/core/services/store/checklist.module";
import Question from "./Question";

export default {
  name: "Questionbyone",
  components: {
    Question
  },

  props: {
    subs: Array,
    question: Object,
    bg: Number,
    index: Number
  },

  data() {
    var d = new Date();
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var today = [year, month, day].join("-");
    return {
      today: today,
      bgs: ["bg-light-warning", "bg-light-success", "bg-light-primary"],
      subquestions: [],
      userList: [],
      tagify: null,
      selectedUsers: [],
      x: 0,
      y: 0,
      isDrawing: false,
      canvas: null,
      ongoingTouches: [],
      vw: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
      vh: Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
    };
  },

  computed: {
    current_bg: {
      get: function() {
        return this.bg >= 2 ? 0 : this.bg + 1;
      }
    }
  },

  methods: {
    submitQuestion() {
      //  console.log(this.question);
      if (this.question.requared) {
        if (
          this.question.answer === null ||
          typeof this.question.answer === "undefined" ||
          this.question.answer === [] ||
          this.question.answer === "" ||
          this.question.answer.length < 1
        ) {
          Swal.fire({
            title: "",
            text: "Заполните поле ответ, вопрос является обязательным!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        } else {
          this.$store.commit(SET_QUESTION_INDEX, this.index + 1);
        }
      } else {
        this.$store.commit(SET_QUESTION_INDEX, this.index + 1);
      }
    },

    setOptions(answers) {
      var options = [];
      answers.forEach(function(answer) {
        options.push({
          text: answer.answerValue,
          value: answer.id
        });
      });
      return options;
    },

    onFileChange(question, e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(question, files[0]);
    },

    createImage(question, file) {
      var reader = new FileReader();

      reader.onload = e => {
        question.answer = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeImage: function(question) {
      question.answer = "";
    },

    showFile: function(question) {
      var ext = question.fileData_id.split(".").pop();
      var file;
      var url = question.fileData_id;
      if (!url.includes(this.$host)) {
        url = this.$host + url;
      }
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          file = '<img src="' + url + '" class="img-fluid" />';
          break;
        case "pdf":
          file = '<embed  src="' + url + '" class="w-100" />';
          break;
        case "webm":
        case "mp4":
        case "mpeg":
          file = '<video controls><source src="' + url + '"></video>';
          break;
      }
      return file;
    },

    createFile: function(question) {
      //var re = /(?:\.([^.]+))?$/;
      //var ext = re.exec(question.value)[1];
      if (question.answer === "") return;
      var ext = question.answer
        .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
        .split("/")
        .pop();
      var file;
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          file = '<img src="' + question.answer + '" class="img-fluid" />';
          break;
        case "pdf":
          file = '<embed  src="' + question.answer + '" class="w-100" />';
          break;
        case "webm":
        case "mp4":
        case "mpeg":
          file =
            '<video controls><source src="' + question.answer + '"></video>';
          break;
      }
      return file;
    },

    createSubs: function() {
      this.subquestions = [];
      var vm = this;
      this.question.answer.forEach(function(order) {
        var index = vm.question.answers.findIndex(i => i.id === order);
        if (vm.question.answers[index].hasOwnProperty("questions")) {
          vm.subquestions = vm.subquestions.concat(
            vm.question.answers[index].questions
          );
        }
      });
    },

    loadUsers() {
      new Promise(resolve => {
        ApiService.get("api/v1/users").then(({ data }) => {
          for (let item of Object.values(data.results)) {
            const state = "danger";
            this.userList.push({
              id: item.id,
              value: item.first_name + " " + item.last_name,
              state: state,
              email: item.username,
              initials:
                item.first_name.charAt(0).toUpperCase() +
                item.last_name.charAt(0).toUpperCase(),
              code:
                item.first_name.charAt(0).toUpperCase() +
                item.last_name.charAt(0).toUpperCase(),
              editable: false
            });
          }
          this.dialog = false;
          this.loadTagify();
          resolve(data);
        });
      }).catch(({ response }) => {
        console.log(response);
      });
    },

    loadTagify() {
      var vm = this;
      var inputElem = vm.$refs.users;
      vm.tagify = new Tagify(inputElem, {
        delimiters: ", ",
        whitelist: vm.userList,
        enforceWhitelist: true,
        maxTags: vm.question.questName === "Укажите специалиста" ? 1 : Infinity,
        templates: {
          dropdownItem: function(tagData) {
            try {
              return (
                '<div class="tagify__dropdown__item" data-id="' +
                tagData.id +
                '">' +
                '<div class="kt-media-card">' +
                '    <span class="kt-media kt-media--' +
                (tagData.initialsState ? tagData.initialsState : "") +
                '">' +
                "        <span>" +
                tagData.initials +
                "</span>" +
                "    </span>" +
                '    <div class="kt-media-card__info">' +
                '        <a href="#" class="kt-media-card__title">' +
                tagData.value +
                "</a>" +
                '        <span class="kt-media-card__desc"> (' +
                tagData.email +
                ")</span>" +
                "    </div>" +
                "</div>" +
                "</div>"
              );
            } catch (err) {
              console.error(err);
            }
          }
        },
        transformTag: function(tagData) {
          tagData.class = "tagify__tag tagify__tag--brand";
        },
        dropdown: {
          classname: "color-blue",
          enabled: 1,
          maxItems: 5,
          searchKeys: ["value", "email"]
        },
        callbacks: {
          add: function(tagData) {
            vm.question.answer.push(tagData.detail.data);
          },
          remove: function(tagData) {
            var index = vm.question.answer.findIndex(
              x => x.id === tagData.detail.data.id
            );
            vm.question.answer.splice(index, 1);
          }
        }
      });
      if (
        (this.question.questName === "Кто провел" ||
          this.question.questName === "Ф.И.О. проводящего инспекцию") &&
        this.question.partition === "Общая информация"
      ) {
        this.tagify.addTags([
          {
            value:
              this.$store.getters.currentUser.user.first_name +
              " " +
              this.$store.getters.currentUser.user.last_name,
            email: this.$store.getters.currentUser.user.username
          }
        ]);
        this.tagify.setReadonly(true);
      }
    },
    //canvas drawing support
    drawLine(e, x1, y1, x2, y2) {
      let ctx = e.target.getContext("2d");
      ctx.beginPath();
      ctx.strokeStyle = "#17a2b8";
      ctx.lineWidth = 1;
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    },

    beginDrawing(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;

      this.isDrawing = true;
    },

    keepDrawing(e) {
      if (this.isDrawing === true) {
        //var el = e.target;
        // y shift
        //var yShift =
        //el.getBoundingClientRect().top + document.documentElement.scrollTop;
        this.drawLine(
          e,
          //this.x / (this.vw / (this.vw - e.target.scrollWidth)),
          //this.y / (this.vh / (this.vh - e.target.scrollHeight)),
          //e.offsetX / (this.vw / (this.vw - e.target.scrollWidth)),
          //e.offsetY / (this.vh / (this.vh - e.target.scrollHeight))
          this.x,
          this.y,
          e.offsetX,
          e.offsetY
        );
        this.x = e.offsetX;
        this.y = e.offsetY;
      }
    },

    stopDrawing(e) {
      if (this.isDrawing === true) {
        //var el = e.target;
        // y shift
        //var yShift =
        //el.getBoundingClientRect().top + document.documentElement.scrollTop;
        this.drawLine(
          e,
          //this.x / (this.vw / (this.vw - e.target.scrollWidth)),
          //this.y / (this.vh / (this.vh - e.target.scrollHeight)),
          //e.offsetX / (this.vw / (this.vw - e.target.scrollWidth)),
          //e.offsetY / (this.vh / (this.vh - e.target.scrollHeight))
          this.x,
          this.y,
          e.offsetX,
          e.offsetY
        );
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
      }
      this.question.answer = e.target.toDataURL("image/png");
    },

    //canvas mobile devices with touch event support
    handleStart(evt) {
      evt.preventDefault();
      // console.log("touchstart.");
      //var el = document.getElementById("canvas");
      var el = evt.target;
      var ctx = el.getContext("2d");
      var touches = evt.changedTouches;

      // y shift
      var yShift =
        el.getBoundingClientRect().top + document.documentElement.scrollTop;

      for (var i = 0; i < touches.length; i++) {
        //    console.log("touchstart:" + i + "...");
        this.ongoingTouches.push(this.copyTouch(touches[i]));
        var color = this.colorForTouch(touches[i]);
        ctx.beginPath();
        ctx.arc(
          touches[i].pageX,
          touches[i].pageY - yShift,
          4,
          0,
          2 * Math.PI,
          false
        ); // a circle at the start
        ctx.fillStyle = color;
        ctx.fill();
        //     console.log("touchstart:" + i + ".");
      }
    },

    handleMove(evt) {
      evt.preventDefault();
      //var el = document.getElementById("canvas");
      var el = evt.target;
      var ctx = el.getContext("2d");
      var touches = evt.changedTouches;

      // y shift
      var yShift =
        el.getBoundingClientRect().top + document.documentElement.scrollTop;

      for (var i = 0; i < touches.length; i++) {
        var color = this.colorForTouch(touches[i]);
        var idx = this.ongoingTouchIndexById(touches[i].identifier);

        if (idx >= 0) {
          //   console.log("continuing touch " + idx);
          ctx.beginPath();
          /*  console.log(
            "ctx.moveTo(" +
              this.ongoingTouches[idx].pageX +
              ", " +
              this.ongoingTouches[idx].pageY +
              ");"
          );*/
          ctx.moveTo(
            this.ongoingTouches[idx].pageX,
            this.ongoingTouches[idx].pageY - yShift
          );
          /*  console.log(
            "ctx.lineTo(" + touches[i].pageX + ", " + touches[i].pageY + ");"
          );*/
          ctx.lineTo(touches[i].pageX, touches[i].pageY - yShift);
          ctx.lineWidth = 4;
          ctx.strokeStyle = color;
          ctx.stroke();

          this.ongoingTouches.splice(idx, 1, this.copyTouch(touches[i])); // swap in the new touch record
          //  console.log(".");
        } else {
          //    console.log("can't figure out which touch to continue");
        }
      }
    },

    handleEnd(evt) {
      evt.preventDefault();
      this.log("touchend");
      //var el = document.getElementById("canvas");
      var el = evt.target;
      var ctx = el.getContext("2d");
      var touches = evt.changedTouches;

      // y shift
      var yShift =
        el.getBoundingClientRect().top + document.documentElement.scrollTop;

      for (var i = 0; i < touches.length; i++) {
        var color = this.colorForTouch(touches[i]);
        var idx = this.ongoingTouchIndexById(touches[i].identifier);

        if (idx >= 0) {
          ctx.lineWidth = 4;
          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.moveTo(
            this.ongoingTouches[idx].pageX,
            this.ongoingTouches[idx].pageY - yShift
          );
          ctx.lineTo(touches[i].pageX, touches[i].pageY - yShift);
          ctx.fillRect(
            touches[i].pageX - 4,
            touches[i].pageY - 4 - yShift,
            8,
            8
          ); // and a square at the end
          this.ongoingTouches.splice(idx, 1); // remove it; we're done
        } else {
          //      console.log("can't figure out which touch to end");
        }
      }
      this.question.answer = evt.target.toDataURL("image/png");
    },

    handleCancel(evt) {
      evt.preventDefault();
      //  console.log("touchcancel.");
      var touches = evt.changedTouches;

      for (var i = 0; i < touches.length; i++) {
        var idx = this.ongoingTouchIndexById(touches[i].identifier);
        this.ongoingTouches.splice(idx, 1); // remove it; we're done
      }
    },

    colorForTouch(touch) {
      var r = touch.identifier % 16;
      var g = Math.floor(touch.identifier / 3) % 16;
      var b = Math.floor(touch.identifier / 7) % 16;
      r = r.toString(16); // make it a hex digit
      g = g.toString(16); // make it a hex digit
      b = b.toString(16); // make it a hex digit
      var color = "#" + r + g + b;
      /*  console.log(
        "color for touch with identifier " + touch.identifier + " = " + color
      );*/
      return color;
    },

    copyTouch({ identifier, pageX, pageY }) {
      return { identifier, pageX, pageY };
    },

    ongoingTouchIndexById(idToFind) {
      for (var i = 0; i < this.ongoingTouches.length; i++) {
        var id = this.ongoingTouches[i].identifier;

        if (id == idToFind) {
          return i;
        }
      }
      return -1; // not found
    },

    log(msg) {
      var p = document.getElementById("log");
      p.innerHTML = msg + "\n" + p.innerHTML;
    },

    //clear canvas
    clearCanvas(e) {
      var canvas = e.target.previousSibling;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.scrollWidth, canvas.scrollHeight);
      if (
        this.question.fileData_id !== "" &&
        this.question.fileData_id !== null
      ) {
        var url = this.question.fileData_id;
        if (!url.includes(this.$host)) {
          url = this.$host + url;
        }
        var image = new Image();
        image.onload = function() {
          ctx.drawImage(image, 0, 0, canvas.scrollWidth, canvas.scrollHeight);
        };
        //image.crossOrigin = "Anonymous";
        image.src = url;
      }
    },
    //manage canvas width
    changeCanvasWidth() {
      if (typeof this.$refs.canvas !== "undefined") {
        this.$refs.canvas.width =
          this.$refs.canvas.parentElement.clientWidth - 10;
      }
    }
  },

  created() {
    window.addEventListener("resize", this.changeCanvasWidth);
    if (this.question.dataTypeId_id === 6) this.loadUsers();
  },
  destroyed() {
    window.removeEventListener("resize", this.changeCanvasWidth);
  },

  mounted() {
    this.changeCanvasWidth();
    if (
      this.question.dataTypeId_id === 2 ||
      this.question.dataTypeId_id === 6
    ) {
      this.question.answer = [];
    } else if (this.question.dataTypeId_id === 7) {
      this.question.answer = this.today;
    } else if (this.question.dataTypeId_id === 8) {
      var vm = this;
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(function(position) {
            vm.question.answer =
              position.coords.latitude + ";" + position.coords.longitude;
          });
        } catch {
          vm.question.answer = "заблокировано пользователем";
        }
      } else {
        vm.question.answer = "не предоставляется устройством";
      }
    } else if (this.question.dataTypeId_id === 12) {
      this.question.answer = 50;
    }
    if (
      this.question.dataTypeId_id === 11 &&
      this.question.fileData_id !== "" &&
      this.question.fileData_id !== null
    ) {
      var canvas = this.$refs.canvas;
      var ctx = canvas.getContext("2d");
      var url = this.question.fileData_id;
      if (!url.includes(this.$host)) {
        url = this.$host + url;
      }
      var image = new Image();
      //console.log(this.question.fileData_id)
      image.onload = function() {
        ctx.drawImage(image, 0, 0, canvas.scrollWidth, canvas.scrollHeight);
      };
      //image.crossOrigin = "Anonymous";
      image.src = url;
    }
  }
};
</script>
