<template>
  <div>
    <div class="card bg-light-primary mt-3">
      <div class="card-body">
        <div
          v-for="question in inspection.sections.questions"
          :key="question.id"
        >
          <Questionbyone
            v-if="
              inspection.sections.questions.findIndex(
                q => q.id === question.id
              ) === index
            "
            :question="question"
            :subs="inspection.section_sub.questions"
            :bg="0"
            :index="index"
          ></Questionbyone>
        </div>
        <div v-if="index === inspection.sections.questions.length">
          <div
            class="card bg-light-primary mt-3"
            v-if="!checkEmpty(getChecklistInfo.mainProc)"
          >
            <div class="card-body">
              <div class="row">
                <label class="col-12"
                  >Укажите новый статус исполнения
                  <span :class="{ 'text-danger': status === '' }"
                    >*</span
                  ></label
                >
              </div>
              <div class="row">
                <div class="col-12">
                  <b-form-radio-group v-model="status" buttons>
                    <b-form-radio
                      value="На исполнении"
                      button-variant="outline-warning"
                    >
                      На исполнении
                    </b-form-radio>
                    <b-form-radio
                      value="Исполнено"
                      button-variant="outline-success"
                    >
                      Исполнено
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="getUuid !== null && mailgroups.length">
            <div class="row">
              <div class="col-12">
                <b-form-group
                  label="Выбор групп рассылок"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox
                    v-model="selectAllMailgroups"
                    value="all"
                    size="lg"
                    switch
                    >Выбрать все</b-form-checkbox
                  >
                  <b-form-checkbox-group
                    id="select-mailgroups"
                    v-model="selectedMailgroups"
                    :aria-describedby="ariaDescribedby"
                    name="select-mailgroups"
                  >
                    <b-form-checkbox
                      v-for="mailgroup in mailgroups"
                      :key="mailgroup.id"
                      :value="mailgroup.id"
                      size="lg"
                    >
                      {{ mailgroup.key }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
                <span class="form-text text-muted"
                  >Выберите группы рассылок, кого уведомить о вашем ответе</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <button
                v-on:click="submit"
                class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                data-wizard-type="action-submit"
              >
                Отправить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_CHECKLIST } from "@/core/services/store/checklist.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import Questionbyone from "./Questionbyone";

export default {
  name: "Byone",

  components: {
    Questionbyone
  },

  props: {
    inspection: Object,
    readonly: Boolean,
    safe: String
  },

  data: () => ({
    mailgroups: [],
    selectedMailgroups: [],
    status: ""
  }),

  computed: {
    ...mapGetters([
      "getChecklistInfo",
      "isAuthenticated",
      "currentUser",
      "getUuid"
    ]),
    getSafe: {
      get: function() {
        return this.safe;
      }
    },
    index: {
      get: function() {
        return this.$store.getters.getQuestionIndex;
      }
    },
    selectAllMailgroups: {
      get: function() {
        return this.mailgroups
          ? this.selectedMailgroups.length == this.mailgroups.length
          : false;
      },
      set: function(value) {
        var selectedMailgroups = [];
        if (value) {
          this.mailgroups.forEach(function(role) {
            selectedMailgroups.push(role.id);
          });
        }

        this.selectedMailgroups = selectedMailgroups;
      }
    }
  },

  created() {
    if (this.isAuthenticated && !this.checkEmpty(this.currentUser)) {
      this.loadMailgroups();
    }
  },

  mounted() {},

  methods: {
    submit() {
      this.$store
        .dispatch(SAVE_CHECKLIST, {
          mailgroups: this.selectedMailgroups,
          status: this.status
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: "Тестирование успешно добавлено!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
          this.$router.push("/checklist/all");
        })
        .catch(error => {
          console.error(error);
          Swal.fire({
            title: "",
            text: "Произошла ошибка, повторите попытку!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },

    async loadMailgroups() {
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/findCustomData/", {
            user_id: this.currentUser.user.id,
            type: "mailer"
          }).then(({ data }) => {
            this.mailgroups = data;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    checkEmpty(obj) {
      return KTUtil.isEmpty(obj);
    }
  }
};
</script>
